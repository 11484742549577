<div
  [ngClass]="{ snackbar: true, 'snackbar-bottom': true }"
  *ngIf="visible"
  [@panelState]="{
    value: 'visible',
    params: { transform: transformOptions, transition: transitionOptions }
  }"
  (@panelState.start)="onAnimationStart($event)"
  (@panelState.done)="onAnimationEnd($event)"
>
  <div
    class="d-flex align-items-center"
    (mouseenter)="stopTimer()"
    (mouseleave)="autoClose()"
  >
    <i *ngIf="data.type === 'success'" class="i-success px-3"></i>
    <i *ngIf="data.type === 'error'" class="i-error px-3"></i>
    <i *ngIf="data.type === 'info'" class="i-info-blue px-3"></i>
    <span>{{ data.message }}</span>
  </div>
  <div class="snackbar-action" *ngIf="hasAction">
    <button
      class="action-button bg-transparent"
      role="button"
      (click)="action()"
    >
      {{ data.action }}
    </button>
  </div>
</div>
