<div class="register-box text-start">
  <app-snackbar [data]="data" [hasAction]="hasAction" [(visible)]="visible" (onAction)="actionClick()"></app-snackbar>

  <form #userForm="ngForm" class="padding-b">
    <div class="form-group has-error">
      <label for="socialSecurityNumber" class="font-20 font-500">Personnummer*</label>
      <input required [disabled]="currentUser !== null" pattern="^(19|20)?(\d{6}([-+]|\\s)\d{4}|(?!19|20)\d{10})$"
        type="text" class="form-control input-line" id="socialSecurityNumber" placeholder="ååååmmdd-xxxx"
        [(ngModel)]="registerData.socialSecurityNumber" name="socialSecurityNumber" minlength="12"
        #socialSecurityNumberControl="ngModel" (input)="getUserDataIfValid(socialSecurityNumberControl)" />

      <span class="text-danger help-block" *ngIf="
        socialSecurityNumberControl.errors?.pattern &&
        socialSecurityNumberControl.dirty &&
        socialSecurityNumberControl.touched &&
        (registerData.socialSecurityNumber?.length !== 12)
      ">
        Personnummer måste vara 12 tecken: ååååmmdd-xxxx
      </span>

      <span class="text-danger help-block" *ngIf="

  socialSecurityNumberControl.errors?.pattern &&
  socialSecurityNumberControl.dirty &&
  socialSecurityNumberControl.touched &&
  (registerData.socialSecurityNumber?.length == 12)
">
        Personnummer fältet är ogiltligt
      </span>

      <span class="text-danger help-block" *ngIf="
    socialSecurityNumberControl.errors?.required &&
    socialSecurityNumberControl.touched
  ">
        Personnummer fältet får inte vara tom
      </span>

    </div>

    <div class="form-group has-error">
      <label for="name" class="font-20 font-500">Namn*</label>
      <input required type="text" class="form-control input-line" id="name" placeholder="Förnamn"
        [(ngModel)]="registerData.firstName" name="name" #nameControl="ngModel" />
      <span class="text-danger help-block" *ngIf="nameControl.errors?.required && nameControl.touched">
        Förnamn fältet får inte vara tom
      </span>
      <input required type="text" class="form-control input-line" id="surname" placeholder="Efternamn"
        [(ngModel)]="registerData.lastName" name="surname" #surnameControl="ngModel" />

      <span class="text-danger help-block" *ngIf="surnameControl.errors?.required && surnameControl.touched">
        Efternamn fältet får inte vara tom
      </span>
    </div>
    <div class="form-group has-error">
      <label class="font-20 font-500">Födelsedag*</label>
      <div class="d-flex justify-content-between">
        <input [disabled]="currentUser !== null" required pattern="(19|20)+[0-9]{2}" type="number"
          class="form-control input-line" id="year" placeholder="År" [(ngModel)]="year" name="year"
          #yearControl="ngModel" />

        <input [disabled]="currentUser !== null" required pattern="([1-9]|1[0-2])" type="number"
          class="form-control input-line mx-2" id="month" placeholder="Mån" [(ngModel)]="month" name="month"
          #monthControl="ngModel" />

        <input [disabled]="currentUser !== null" required pattern="([1-9]|[12][0-9]|3[01])" type="number"
          class="form-control input-line" id="day" placeholder="Dag" [(ngModel)]="day" name="day"
          #dayControl="ngModel" />
      </div>
      <span class="text-danger help-block d-block" *ngIf="yearControl.errors?.required && yearControl.touched">
        År fältet får inte vara tom: XXXX
      </span>
      <span class="text-danger help-block d-block"
        *ngIf="yearControl.errors?.pattern && yearControl.touched && yearControl.dirty">
        År fältet är inte giltigt: XXXX
      </span>
      <span class="text-danger help-block d-block" *ngIf="monthControl.errors?.required && monthControl.touched">
        Månad fältet får inte vara tom
      </span>
      <span class="text-danger help-block d-block" *ngIf="monthControl.errors?.pattern">
        Månad är inte giltigt
      </span>
      <span class="text-danger help-block d-block" *ngIf="dayControl.errors?.required && dayControl.touched">
        Dag fältet får inte vara tom
      </span>
      <span class="text-danger help-block d-block" *ngIf="dayControl.errors?.pattern">
        Dag är inte giltigt
      </span>
    </div>

    <div class="form-group has-error">
      <label for="city" class="font-20 font-500">Stad*</label>
      <input required type="text" class="form-control input-line" id="city" placeholder=""
        [(ngModel)]="registerData.address.city" name="city" #cityControl="ngModel" />

      <span class="help-block text-danger" *ngIf="cityControl.errors?.required && cityControl.touched">
        Stad fältet får inte vara tom
      </span>
    </div>
    <div class="form-group has-error">
      <label for="street" class="font-20 font-500">Gata*</label>
      <input required type="text" class="form-control input-line" id="street" placeholder=""
        [(ngModel)]="registerData.address.street" name="street" #streetControl="ngModel" />

      <span class="help-block text-danger" *ngIf="streetControl.errors?.required && streetControl.touched">
        Gata fältet får inte vara tom
      </span>
    </div>

    <div class="form-group has-error">
      <label for="zipCode" class="font-20 font-500">Post Nummer*</label>
      <input required type="text" class="form-control input-line" id="zipCode" placeholder=""
        [(ngModel)]="registerData.address.zipCode" name="zipCode" #zipCodeControl="ngModel" />

      <span class="help-block text-danger" *ngIf="zipCodeControl.errors?.required && zipCodeControl.touched">
        Post Nummer fältet får inte vara tom
      </span>
    </div>

    <div class="form-group has-error">
      <label for="gender" class="font-20 font-500">Kön*</label>
      <div class="d-flex justify-content-center">
        <div class="select-wrapper my-2">
          <select required name="gender"
          id="gender"
          [(ngModel)]="registerData.gender"
          (ngModelChange)="onGenderChange($event)"
          #genderControl="ngModel"
          class="form-control">
            <!-- Loop through genderDisplay to create options -->
            <option *ngFor="let key of (genderDisplay | keyvalue)" [value]="key.key">
              {{ key.value }}
            </option>
          </select>
        </div>
      </div>
      <span class="text-danger help-block" *ngIf="genderControl.errors?.required && genderControl.touched">
        Kön fältet får inte vara tom
      </span>
    </div>



    <div class="form-group">
      <label for="email" class="font-20 font-500">E-postadress*</label>
      <input pattern="^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required type="email" class="form-control input-line"
        id="email" autocomplete="username" aria-describedby="emailHelp" placeholder="" [(ngModel)]="registerData.email"
        name="email" #emailControl="ngModel" />

      <span class="text-danger help-block" *ngIf="emailControl.errors?.pattern && emailControl.touched">
        Ogiltig e-postadress
      </span>

      <span class="text-danger help-block" *ngIf="emailControl.errors?.required && emailControl.touched">
        Email fältet får inte vara tom
      </span>
    </div>

    <div class="form-group">
      <label for="password1" class="font-20 font-500">
        {{ currentUser ? "nytt Lösenord" : "Lösenord*" }}
      </label>
      <input required type="password" class="form-control input-line" id="password1" placeholder=""
        autocomplete="new-password" [(ngModel)]="registerData.password" name="password1" #passwordControl1="ngModel" />

      <span class="text-danger help-block" *ngIf="
          passwordControl1.errors?.required &&
          passwordControl1.touched &&
          !currentUser
        ">
        Lösenord fältet får inte vara tom
      </span>
    </div>

    <div class="form-group has-error">
      <label for="password2" class="font-20 font-500">Repetera lösenord*</label>
      <input required type="password" class="form-control input-line" id="password2" placeholder=""
        [(ngModel)]="password2" name="password2" autocomplete="new-password" #passwordControl2="ngModel"
        (ngModelChange)="checkSame($event)" />

      <span class="text-danger help-block" *ngIf="
          passwordControl2.errors?.required &&
          passwordControl2.touched &&
          !currentUser
        ">
        Lösenord fältet får inte vara tom
      </span>

      <span class="text-danger help-block" *ngIf="showErrorMessage === false">
        Lösenorden är inte lika
      </span>
    </div>
    <div class="form-group form-check">
      <input #AgreeMarketing name="agreeMarketing" type="checkbox" class="p-1" id="agreeMarketing"
        (change)="onAgreeMarketingChanged(AgreeMarketing.checked)" [checked]="registerData.agreeMarketing" />
      <label class="font-18 font-500 ms-1" for="agreeMarketing">Agree Marketing</label>
    </div>
    <div class="form-group form-check">
      <input #SubscribeToEmailNotification name="SubscribeToEmailNotification" type="checkbox" class="p-1"
        id="SubscribeToEmailNotification" (change)="
          onSubscribeToEmailChanged(SubscribeToEmailNotification.checked)
        " [checked]="registerData.subscribeToEmailNotification" />
      <label class="font-18 font-500 ms-1" for="SubscribeToEmailNotification">Subscribe to email notification</label>
    </div>

    <button *ngIf="!currentUser" type="submit" class="my-3 w-100" [ngClass]="{
        disabled:
          userForm.invalid ||
          !userForm.dirty ||
          showErrorMessage === false ||
          emailExists
      }" [disabled]="
        userForm.invalid ||
        !userForm.dirty ||
        showErrorMessage === false ||
        emailExists
      " (click)="registerNewUser()">
      Register
    </button>
    <button *ngIf="currentUser" type="submit" class="my-3 w-100" (click)="registerNewUser()">
      Update
    </button>
  </form>
</div>