// Define an enum for Gender
export enum Gender {
    EjValt = 0,    // 0 for "EjValt"
    Man = 1,       // 1 for "Man"
    Kvinna = 2,    // 2 for "Kvinna"
    Annat = 3      // 3 for "Annat"
  }
  
  // Create a mapping for display strings
  export const genderDisplay = {
    [Gender.EjValt]: "Ej valt",
    [Gender.Man]: "Man",
    [Gender.Kvinna]: "Kvinna",
    [Gender.Annat]: "Annat"
  } as const;
  
  // This provides both types: Gender will be number, and display will be string
  export type GenderType = keyof typeof genderDisplay;