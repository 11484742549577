import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiService } from "./http.service";

@Injectable({
  providedIn: "root",
})
export class ValidateEmailServiceService {
  constructor(
    private genericHttpService: ApiService,
    private http: HttpClient
  ) { }

  private authRoutes = environment.api.authenticate;

  validateEmail(email: string): Observable<any> {
    return this.http.post<any>(this.authRoutes.validateEmail, { Email: email });
  }
}
