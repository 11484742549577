import { Gender } from "./gender";

interface Address {
  city: string | null;
  zipCode: string | null;
  country: string | null;
  street: string | null;
}

export class Register {
  socialSecurityNumber: string;
  firstName: string;
  lastName: string;
  //fullName: string;
  gender: Gender;
  email: string;
  password: string;
  agreeMarketing: boolean;
  subscribeToEmailNotification: boolean;
  //havePet: boolean;
  //petNum: number;
  //petType: string;
  address: Address = {
    city: null,
    street: null,
    zipCode: null,
    country: null,
  };
}
