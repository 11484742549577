import {
  animate,
  animation,
  style,
  transition,
  trigger,
  useAnimation,
} from "@angular/animations";
import { CommonModule } from "@angular/common";
import { Component, Input, Output, EventEmitter } from "@angular/core";
import { SnackbarData } from "src/app/shared/types/SnackbarData";


const showAnimation = animation([
  style({ transform: "{{transform}}", opacity: 0 }),
  animate("{{transition}}"),
]);
const hideAnimation = animation([
  animate("{{transition}}", style({ transform: "{{transform}}", opacity: 0 })),
]);
@Component({
  standalone: true,
  imports: [CommonModule],
  selector: "app-snackbar",
  templateUrl: "./snackbar.component.html",
  styleUrls: ["./snackbar.component.css"],
  animations: [
    trigger("panelState", [
      transition("void => visible", [useAnimation(showAnimation)]),
      transition("visible => void", [useAnimation(hideAnimation)]),
    ]),
  ],
  // animations: [snackbarAnimation],
})
export class SnackbarComponent {
  container: any;
  _visible: boolean = false;
  @Input() get visible(): boolean {
    return this._visible;
  }
  set visible(val) {
    this._visible = val;
    this.visibleChange.emit(this._visible);
    if (this._visible) this.autoClose();
  }
  timeoutId: ReturnType<typeof setTimeout>;
  @Input() data : SnackbarData;
  @Input() hasAction = false;

  @Output() visibleChange: EventEmitter<any> = new EventEmitter();
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  transformOptions: any = "translate3d(-50%, 100%, 0px)";
  transitionOptions: string = "150ms cubic-bezier(0, 0, 0.2,1)";
  constructor() {}

  ngOnInit(): void {}

  action(): void {
    this.onAction.emit({});
    this.hide();
  }

  hide() {
    this.visibleChange.emit(false);
  }

  onAnimationStart(event: any) {
    if (event.toState === "visible") {
      this.container = event.element;
      this.appendContainer();
    }
  }

  onAnimationEnd(event: any) {
    if (event.toState === "void") {
      this.hide();
    }
  }

  appendContainer() {
    document.body.appendChild(this.container);
  }

  stopTimer() {
    clearTimeout(this.timeoutId);
  }

  autoClose() {
    this.timeoutId = setTimeout(() => {
      this.hide();
    }, 3000);
  }
}
